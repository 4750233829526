// Footer.js
import React from "react";
import "./Services.css";
import sapImage from "../assets/sap.png";
import servicesImage from "../assets/services.png";
import applicationImage from "../assets/application.png";
import enterpriseImage from "../assets/enterprise.png";
import cloudbasedImage from "../assets/cloudbased.png";
import mobileApplicationImage from "../assets/mobile.png";
import androidImage from "../assets/android.png";
import appleImage from "../assets/apple.png";
import crossImage from "../assets/cross.png";
import ecomImage1 from "../assets/ecom1.png";
import ecomImage2 from "../assets/ecom2.png";
import dataScienceImage from "../assets/datascience.png";
// import { Link } from "react-router-dom";

const Services = () => {
  const openLink = () => {
    window.location.href = "https://www.thinkbigsoft.in";
  };
  return (
    <section className="services-container">
      <img className="services-image" src={servicesImage} alt="servicesImage" />
      <h1>SAP and ERP</h1>
      <div className="sap-service-container">
        <div className="sap-service-item">
          <p>
            With our extensive SAP expertise and multi-disciplinary business
            backgrounds, ThinkBiG Software solutions consultants can deliver a
            broad range of SAP solutions.
          </p>
          <h3>Benefits</h3>
          <ul className="benefits-list">
            <li>
              Achieve short-term technology goals, cost-effectively and rapidly
            </li>
            <li>
              Improved Recruitment Capabilities And Shorter Hiring Timelines
            </li>
            <li>
              Ensure appropriate skills and resources are applied to the project
            </li>
            <li>
              Ensure affordability and stay within the budget for the project
            </li>
            <li>End-to-end Implementation & Rollouts</li>
            <li>Enterprise Shared Services</li>
          </ul>
        </div>
        <div className="sap-service-item">
          <img src={sapImage} alt="sap" className="sap-image" />
        </div>
      </div>
      <h1>Medical Coding Services</h1>
      <div className="sap-service-container">
      <div className="sap-service-item">
     
       <p> We are providing international standard training in all domains. We are
        developing in yourskills and knowledge that relate to the across
        profession. We have specific goals for improving the capability,
        capacity, Productivity, and Performance. It forms the core of
        apprenticeships and provides the backbone of content at your concern
        career. In addition, we are helping you to get trained for your required
        occupation or profession.</p>
     
     
        <ul  className="benefits-list">
          <li>
            Anatomy, Physiology & Pathology classes will be taken through
            visualized videos & Power Point Presentation{" "}
          </li>
          <li>
            Hands-on Training for ICD, CPT & HCPCS for better understanding{" "}
          </li>
          <li>
            OJT (On the Job Training) with Practical sessions & Updated
            Software’s will be provided{" "}
          </li>
          <li>
            Highly Researched & Customized Training Materials that makes
            Successful Medical Coder{" "}
          </li>
          <li>
            CPC Certified senior Trainer having years of Coding & Training
            experience{" "}
          </li>
          <li>
            AAPC Coding materials for every year (Updated Coding Sessions){" "}
          </li>
          <li>User friendly Interactive Modules </li>
        </ul>
     
      </div>
      </div>
      <div className="link-button-container">
        {" "}
        <button className="link-button" onClick={openLink}>
          Join Us{" "}
        </button>
      </div>

      <div className="application-development-container">
        <h1>Application Development Services</h1>
        <div className="grid-item">
          <img
            className="application-image"
            src={applicationImage}
            alt="application"
          />
          <p>
            In the era of ever-changing technologies and practices, choosing the
            right technology partner gets you one step closer to success. We at
            ThinkBiG deliver highly customized, robust, fast, and agile
            solutions that add value to your business. Be it Web, Cloud, or a
            Desktop application, we are your right partners to reach your goal
            faster. With our expertise, we can partner with you for all of your
            technology needs – be it a turnkey solution or an upgrade to your
            existing system. At ThinkBiG, we strongly believe in an agile
            delivery model and professional DevOps to minimize amateur
            intervention on all our solutions.
          </p>
        </div>

        <h3>Enterprise Application and Cloud Based Application</h3>
        <div className="grid-item">
          <p>
            Be it a first-of-a-kind commercial ERP, a start-up idea, or a
            proprietary or an LOB application, ThinkBiG enables you to create
            solutions as splendid as possible from scratch, with you being the
            sole owner of the source-code and IPs. ThinkBiG also helps you
            leverage your existing IT investments by re-designing, refactoring,
            and enhancing the outdated and inefficient systems you currently
            use. ThinkBiG has established migrations to newer platforms,
            refinement of development practices and technical architecture
            review.
          </p>
          <img
            className="enterprise-image"
            src={enterpriseImage}
            alt="enterprise"
          />
        </div>
        <div className="grid-item">
          <img
            className="cloud-based-image"
            src={cloudbasedImage}
            alt="cloudbased"
          />
          <p>
            ThinkBiG provides a complete solution that supports the design,
            development, and deployment of applications by utilizing the agility
            & cost-effective attributes of the Cloud. ThinkBiG thoroughly
            explores cloud strategy to eliminate barriers from innovation and
            transforming applications into cost-effective solutions. We are
            flexible working with platforms like Amazon Web Services (AWS),
            Microsoft Azure, Google Cloud, or any other cloud platforms.
          </p>
        </div>

        <h1>Mobile Application Development</h1>
        <div className="grid-item">
          <p>
            ThinkBiG provides a complete solution that supports the design,
            development, and deployment of applications by utilizing the agility
            & cost-effective attributes of the Cloud. ThinkBiG thoroughly
            explores cloud strategy to eliminate barriers from innovation and
            transforming applications into cost-effective solutions. We are
            flexible working with platforms like Amazon Web Services (AWS),
            Microsoft Azure, Google Cloud, or any other cloud platforms.
          </p>

          <img
            className="mobile-application-image"
            src={mobileApplicationImage}
            alt="mobile application"
          />
        </div>
        <h3>Android</h3>
        <div className="grid-item">
          <img
            className="android-image"
            src={androidImage}
            alt="android application"
          />
          <p>
            ThinkBiG provides a complete solution that supports the design,
            development, and deployment of applications by utilizing the agility
            & cost-effective attributes of the Cloud. ThinkBiG thoroughly
            explores cloud strategy to eliminate barriers from innovation and
            transforming applications into cost-effective solutions. We are
            flexible working with platforms like Amazon Web Services (AWS),
            Microsoft Azure, Google Cloud, or any other cloud platforms.
          </p>
        </div>
        <h3>IOS</h3>
        <div className="grid-item">
          <p>
            ThinkBiG provides a complete solution that supports the design,
            development, and deployment of applications by utilizing the agility
            & cost-effective attributes of the Cloud. ThinkBiG thoroughly
            explores cloud strategy to eliminate barriers from innovation and
            transforming applications into cost-effective solutions. We are
            flexible working with platforms like Amazon Web Services (AWS),
            Microsoft Azure, Google Cloud, or any other cloud platforms.
          </p>

          <img className="apple-image" src={appleImage} alt="ios application" />
        </div>
        <h3>Cross-Platform</h3>
        <div className="grid-item">
          <img
            className="cross-image"
            src={crossImage}
            alt="cross application"
          />
          <p>
            ThinkBiG provides a complete solution that supports the design,
            development, and deployment of applications by utilizing the agility
            & cost-effective attributes of the Cloud. ThinkBiG thoroughly
            explores cloud strategy to eliminate barriers from innovation and
            transforming applications into cost-effective solutions. We are
            flexible working with platforms like Amazon Web Services (AWS),
            Microsoft Azure, Google Cloud, or any other cloud platforms.
          </p>
        </div>

        <h1>E-Commerce Website Development</h1>
        <div className="grid-item">
          <p>
            A robust e-Commerce website serves as the foundation of online
            retail business. Out of the Box understands the importance of
            utilizing the latest technology to maximize profitability. Our
            e-Commerce solutions offer the best in standard features and
            functionality while remaining agile and completely customizable. Our
            team will discuss any custom functionality needs to tailor a
            solution that matches your brand and e-Commerce goals. We use the
            modern trends for e-commerce development, including Chatbots, social
            media integration, engaging UX and one-click checkout techniques to
            ensure the best shopping experience. Our experts walk with the
            emerging technologies and build influential e-commerce storefronts
            based on Shopify, WooCommerce, Magento, BigCommerce, PrestaShop,
            OpenCart, Drupal, Joomla and custom made Node + Angular development
            platforms.
          </p>
          <img className="ecom-image-1" src={ecomImage1} alt="ecom1" />
        </div>
        <div className="grid-item">
          <img className="ecom-image-2" src={ecomImage2} alt="ecom2" />
          <p>
            Our data analytics and visualization services helps organizations
            explore new capabilities, streamline existing processes, improve
            forecasting abilities and respond to market changes effectively. We
            help organizations adopt data driven insights using technologies
            like Power Bi, Hadoop, D3 JS, and Kibana with Elastic search, etc.
            We empower managers with automated reporting for faster decision
            making. Our solutions are focused on understanding the activities
            around the processes to mine insightful inputs from process
            inefficiencies and bottlenecks. This solution helps your
            organization in converting large volumes of data into user friendly
            dashboards. This realtime data analysis will gauge the performance
            of the system for strategical decisions.
          </p>
        </div>

        <h1>Data Science</h1>
        <div className="grid-item">
          <p>
            Our data analytics and visualization services helps organizations
            explore new capabilities, streamline existing processes, improve
            forecasting abilities and respond to market changes effectively. We
            help organizations adopt data driven insights using technologies
            like Power Bi, Hadoop, D3 JS, and Kibana with Elastic search, etc.
            We empower managers with automated reporting for faster decision
            making. Our solutions are focused on understanding the activities
            around the processes to mine insightful inputs from process
            inefficiencies and bottlenecks. This solution helps your
            organization in converting large volumes of data into user friendly
            dashboards. This realtime data analysis will gauge the performance
            of the system for strategical decisions.
          </p>
          <img
            className="data-science-image"
            src={dataScienceImage}
            alt="datascience"
          />
        </div>
        <div className="link-button-container">
          {" "}
          <button className="link-button" onClick={openLink}>
            Join Us{" "}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;
