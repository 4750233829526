import React, { useState, useEffect } from "react";
import {
  HashRouter as Router, // Changed BrowserRouter to HashRouter
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faBars } from "@fortawesome/free-solid-svg-icons";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import OurTeam from "./components/OurTeam";
import ContactUs from "./components/ContactUs";
import Services from "./components/Services";
import Logo from "./assets/tb_final-1-removebg-preview.png";
import "./App.css";

const App = () => {
  const [theme, setTheme] = useState("light-theme");
  const [showNav, setShowNav] = useState(window.innerWidth > 800);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === "light-theme" ? "dark-theme" : "light-theme"
    );
  };

  const toggleNav = () => {
    setShowNav((prevShowNav) => !prevShowNav);
  };

  const closeNav = () => {
    if (window.innerWidth <= 800) {
      setShowNav(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setShowNav(window.innerWidth > 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <div>
        <nav>
          <div className="logo-wrapper">
            <img src={Logo} alt="Company Logo" className="logo" />
          </div>

          {window.innerWidth <= 800 && (
            <button onClick={toggleNav} className="hamburger-button">
              <FontAwesomeIcon icon={faBars} />
            </button>
          )}

          <div className={`nav-links${showNav ? " show" : ""}`}>
            <NavLink to="/" end activeClassName="active" onClick={closeNav}>
              Home
            </NavLink>
            <NavLink to="/about" activeClassName="active" onClick={closeNav}>
              About Us
            </NavLink>
            <NavLink to="/services" activeClassName="active" onClick={closeNav}>
              Services
            </NavLink>
            <NavLink to="/team" activeClassName="active" onClick={closeNav}>
              Our Team
            </NavLink>
            <NavLink to="/contact" activeClassName="active" onClick={closeNav}>
              Contact Us
            </NavLink>
          </div>

          <button onClick={toggleTheme} className="theme-toggle-button">
            {theme === "light-theme" ? (
              <FontAwesomeIcon icon={faMoon} />
            ) : (
              <FontAwesomeIcon icon={faSun} />
            )}
          </button>
        </nav>

        <main onClick={closeNav}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<OurTeam />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
