import React from "react";
import "./AboutUs.css"; // Import the CSS file for styling
import whatWeDoImage from "../assets/what_we_do_image.png";
import aboutUsImage from "../assets/about.png";
import section2Image from "../assets/our_strength_image.png";
import section3Image from "../assets/how_we_different_image.png";
import partnership from "../assets/partnership.png"
import timeAndMaterials from "../assets/time-and-materials.png"
import fixedPrice from "../assets/fixed-price.png"
import offshoreDevelopment from "../assets/offshore-development.png"
// Import images
import wordpress from '../assets/wordpress.png';
import scala from '../assets/scala.png';
import php from '../assets/php.png';
import oracle from '../assets/oracle.png';
import node from '../assets/node.png';
import mysql from '../assets/mysql.png';
import microsoftAzure from '../assets/microsoft-azure.png';
import javaServerFaces from '../assets/javaserverfaces.png';
import python from '../assets/python.png';
import jsp from '../assets/jsp.png';
import angular from '../assets/angular.png';
import git from '../assets/git.png';
import github from '../assets/github.png';
import java from '../assets/java.png';
import linux from '../assets/linux.png';
import bootstrap from '../assets/bootstrap.png';
import bigData from '../assets/big-data.png';
import aws from '../assets/aws.png';
import apacheSpark from '../assets/apache-spark.png';
import htmlCssJavascript from '../assets/html-css-javascript.png';
import spring from '../assets/spring.png';
import vueJs from '../assets/vue-js.png';
import sap from '../assets/sap.png';
import salesforce from '../assets/salesforce.png';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <img className="about-us-image" src={aboutUsImage} alt="aboutUsImage" />

      {/* Section 1 */}
      <div className="service-value-section">
        <p>
          As a leading technology company leveraging the power of Artificial
          Intelligence and digital era to automate the product development and
          testing. We assist our customer to optimize their process to maximize
          their profits/savings. We strive to be thought leaders, not industry
          followers and always stay abreast of the latest products, methods and
          technology. We nourished in our People and their capability and
          framework to support the achievement of high performance, both from an
          individual and organizational perspective. Ensuring customer
          satisfaction as our prime focus. We achieve this with our Team of
          certified experts who can work on latest technologies and modern
          design techniques. We adopt Agile or waterfall methodologies for the
          development process. During SDLC, we adhere to the process to ensure
          your business requirements are met on time.
        </p>
        <div className="list-content">
          <h2>Our Service and Values </h2>
          <li>Experienced Team</li>
          <li>Complete Technical Competency</li>
          <li>Exemplary practices</li>
          <li>Data Security</li>
          <li>Positive Corporate Culture</li>
          <li>Absolute Support</li>
          <li>Continuous Communication</li>
          <li>Excellent & on-Time Delivery</li>
        </div>
        <div className="animated-image-container">
          {/* You can add your own content or image here later */}
        </div>
      </div>

      <div className="what-we-do-section">
        <div className="about-us-section">
          <h2>What We Do</h2>
          <p>
            We are a leading technology company leveraging the power of AI and
            the digital era to automate your product development and test
            automation. We assist our customers in optimizing their processes to
            maximize profits and savings. We strive to be thought leaders, not
            industry followers, and always stay abreast of the latest products,
            methods, and technology so you don’t have to. We invest in our
            people and their capabilities and provide a framework to support the
            achievement of high performance, both from an individual and
            organizational perspective.
          </p>
        </div>
        <div className="animated-image-container">
          <img
            src={whatWeDoImage}
            alt="Company Logo"
            className="animated-image"
          />
        </div>
      </div>

      {/* Section 2 */}
      <div className="our-strength-section">
      <div className="animated-image-container">
          <img
            src={section2Image}
            alt="Section 2 "
            className="animated-image"
          />
        </div>
        <div className="about-us-section">
   
          <h2>Our Strength</h2>
          <p>
            Ensuring customer satisfaction is our core focus. We achieve this
            with our Team of certified experts who can work on the latest
            technologies and modern design techniques. We adopt Agile or
            waterfall methodologies for the development process. During SDLC, we
            adhere to the process to ensure your business requirements are met
            on time.
          </p>
        </div>
     
      </div>
      {/* Section 3 */}
      <div className="how-are-we-different-section">
        <div className="about-us-section">
          <h2> How are we different </h2>
          <p>
            ThinkBiG thrives to be a premium services provider in the industry
            by creating value for your business needs. We trust in adopting the
            right combination of Technologies, People, and Process. We strongly
            feel our team is an asset for the company. Our HR processes have
            been rigged to source talents, design continuous training programs
            on core competencies that empower them for challenging roles. We
            adhere to SDLC that ensures your delivery metrics. Being a service
            provider, we strongly believe in our process to maintain consistency
            and deliver quality results on time.
          </p>
        </div>
        <div className="animated-image-container">
          <img
            src={section3Image}
            alt="Section 3 "
            className="animated-image"
          />
        </div>
      </div>
      <section>
        <h1>Engagement Models</h1>
      <div class="grid-container">
        <div class="about-us-grid-item">
            <div class="top">
                <img className="about-us-engagement-images" src={partnership} alt="partner"/>
            </div>
          
            <div class="bottom">
            <h3>Partnership</h3>
                <p>An ideal partnership empowers its partners equally. As we deliver technical solution on time to meet your expectations, allowing you to focus on the important time and the bound management task, we work under your brand name as an extension of your company .</p>
            </div>
        </div>
        <div class="about-us-grid-item">
            <div class="top">
                <img  className="about-us-engagement-images" src={timeAndMaterials} alt="time and materials"/>
            </div>
            <div class="bottom">
            <h3>Time and Materials</h3>
                <p>Through the Time & Material Business Model, at ThinkBiG we offer you the flexibility to alter specifications with revisions in requirements based on your business needs.</p>
            </div>
        </div>
      
        <div class="about-us-grid-item">
            <div class="top">
                <img  className="about-us-engagement-images" src={offshoreDevelopment}
                 alt="offshore development"/>
            </div>
            <div class="bottom">
            <h3>Offshore Development</h3>
                <p>At ThinkBiG we follow a Fixed Price Business Model for projects that are specifically defined in terms of their requirements, schedules and project path. </p>
            </div>
        </div>
        <div class="about-us-grid-item">
            <div class="top">
                <img  className="about-us-engagement-images" src={
                  fixedPrice
                } alt="fixed price"/>
            </div>
            <div class="bottom">
            <h3>Fixed Price</h3>
                <p>This model involves teams who act as an extension of your team. They are located at a different location and managed by an offshore development organization that only have  proven records of offshore delivery capabilities.</p>
            </div>
        </div>
    </div>
      </section>
      <section>
      <h1> Technology Stack</h1>
        <div class="technology-stack-container">
       
        <div class="stack-item"><img src={wordpress} alt="WordPress"/></div>
    <div class="stack-item"><img src={scala} alt="Scala"/></div>
    <div class="stack-item"><img src={php} alt="PHP"/></div>
    <div class="stack-item"><img src={oracle} alt="Oracle"/></div>
    <div class="stack-item"><img src={node} alt="Node.js"/></div>
    <div class="stack-item"><img src={mysql} alt="MySQL"/></div>
    <div class="stack-item"><img src={microsoftAzure} alt="Microsoft Azure"/></div>
    <div class="stack-item"><img src={javaServerFaces} alt="JavaServer Faces"/></div>
    <div class="stack-item"><img src={python} alt="Python"/></div>
    <div class="stack-item"><img src={jsp} alt="JSP"/></div>
    <div class="stack-item"><img src={angular} alt="Angular"/></div>
    <div class="stack-item"><img src={git} alt="Git"/></div>
    <div class="stack-item"><img src={github} alt="GitHub"/></div>
    <div class="stack-item"><img src={java} alt="Java"/></div>
    <div class="stack-item"><img src={linux} alt="Linux"/></div>
    <div class="stack-item"><img src={bootstrap} alt="Bootstrap"/></div>
    <div class="stack-item"><img src={bigData} alt="Big Data"/></div>
    <div class="stack-item"><img src={aws} alt="AWS"/></div>
    <div class="stack-item"><img src={apacheSpark} alt="Apache Spark"/></div>
    <div class="stack-item"><img src={htmlCssJavascript} alt="HTML CSS JavaScript"/></div>
    <div class="stack-item"><img src={spring} alt="Spring"/></div>
    <div class="stack-item"><img src={vueJs} alt="Vue.js"/></div>
    <div class="stack-item"><img src={sap} alt="SAP"/></div>
    <div class="stack-item"><img src={salesforce} alt="Salesforce"/></div>
</div>
      </section>
    </div>
  );
};

export default AboutUs;
