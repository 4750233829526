// src/components/Home.js
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import homeImage1 from "../assets/welcome.png";
import homeImage2 from "../assets/img16.jpg";
import homeImage3 from "../assets/img3.jpg";
import homeImage4 from "../assets/img17.jpg";
import homeImage5 from "../assets/img14.avif";
import homeImage6 from "../assets/img4.jpg";
// import  ghrImage from "../assets/ghr.png"
import schneiderImage from "../assets/schneiderelectric.png"
import wissenImage from "../assets/wisseninfotech.png"
import thalesImage from "../assets/thales.png"
import mexrImage from "../assets/MERX.jpeg"


const Home = () => {
  const [carouselImages] = useState([
    homeImage2,
    homeImage3,
    homeImage4,
    homeImage5,
    homeImage6,
  ]); // Add more images to the array

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <div className="home-container">
      <div className="carousel-container">
      <Slider {...settings}>
        {carouselImages.map((image, index) => (
          <img
            key={index}
            
            className="carousel-image"
            src={image}
            alt={`carouselImage${index}`}
          />
        ))}
      </Slider>
      </div>

      <section className="content-image-container">
        <div className="welcome-content">
          {/* <h1>Welcome to ThinkBiG</h1> */}
          <p>
            ThinkBiG thrives to be a premium services provider in the industry
            by creating value for the business needs. We trust in adopting the
            right combination of Technologies, People, and Process.
          </p>
          <p>
            We strongly believe that our team is an asset for our growth where
            our Human Resource processes have been rigged to source talents and
            designed continuous training programs on core competencies that
            empower them for challenging roles.
          </p>
          <p>
            ThinkBiG software solutions are a dynamic medley of offshore project
            applications and ERP implementation services and business
            consultancy services. We thrive on building a strong relationship
            with our clients that lasts on the basis of our effective
            consultancy and quick dispensation of IT solutions on reasonable
            costs.
          </p>
        </div>
        {/* You can keep the existing image or remove it */}
        <img className="homeImage" src={homeImage1} alt="homeImage" />
      </section>
      <h1>Our Premium Clients</h1>
      <section className="our-premium-clients">
        
     
            {/* <img src={ghrImage} alt="global" className="client-images"></img> */}
            <img src={schneiderImage} alt="schneider" className="client-images"></img>
            <img src={wissenImage} alt="wissen" className="client-images"></img>
            <img src={thalesImage} alt="global" className="client-images"></img>
            <img src={mexrImage} alt="global" className="client-images"></img>
      </section>
     
    </div>
  );
};

export default Home;
