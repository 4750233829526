import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OurTeam.css";
import ourTeamImage from "../assets/team.png";
// import officeImage1 from "../assets/office1.JPG";
import officeImage2 from "../assets/office2.JPG";
import officeImage3 from "../assets/office3.JPG";
// import officeImage4 from "../assets/office4.JPG";
import officeImage5 from "../assets/office5.JPG";
import officeImage6 from "../assets/office6.JPG";
import certificateImage from "../assets/certificate.png"
import freshersImage from "../assets/freshers.png"
import employeeImage from "../assets/employee.png"
import extramileImage from "../assets/extramile.png"

const OurTeam = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <div className="OurTeamContainer">

      <div className="content-container">
        <img className="our-team-image" src={ourTeamImage} alt="ourTeamImage" />
        <div className="our-team-grid-container">
        <div className=" our-team-grid-heading">
          <h1>thinkBIG</h1>
        </div>
          <div className=" our-team-grid-para">
            <p>Our ability to deliver outstanding results for our clients starts with our team of smart, capable , experts. As unique as we are individually, we share a mutual passion for technology and doing excellent work for our clients and partners. We pride ourselves on our strategic, integrated approach to technologies and the results it delivers.</p>
          </div>
          <div className="certificate-container">
            <img className="certificate-image" src={certificateImage} alt="certificate"></img>
            <h3>Certifications and Training</h3>
            <p>We encourage our employees to take external and internal certifications and training to be updated with the latest technology trends. Our employees are well-equipped with the necessary knowledge to tackle today’s toughest technology and business problems.</p>
          </div>
          <div className="freshers-container">
            <img className="freshers-image" src={freshersImage} alt="freshers"></img>
            <h3>In-House Fresher Classes</h3>
            <p>We provide customized classes, state-of-the-art training programs that develops methodologies ,quality processes and exposure to end to end development of a mini projects to our employees before they begin working on client projects to ensure they are familiar with SDLC process</p>
          </div>
          <div className="employee-container">
            <img className="employee-image" src={employeeImage} alt="employee"></img>
            <h3>Employee Empowerment</h3>
            <p>We empower employees with the ability to move to different roles and responsibility vertical and horizontally in our company, so employees have the opportunities to advance their career and round-out their skills and experience in various domain.</p>
          </div>
          <div className="extramile-container">
            <img className="extramile-image" src={extramileImage} alt="extramile"></img>
            <h3>Go the Extra Mile</h3>
            <p>Success isn’t a straight line. We acknowledge our mistakes and learn from them to move us closer to where we want to go.We believe in going above and beyond, whether that’s solving internal challenges or satisfying  our customer</p>
          </div>
      </div>

        <Slider {...sliderSettings} className="images-container">
          {/* <img src={officeImage1} alt="office1" className="office-image" /> */}
          <img src={officeImage2} alt="office2" className="office-image" />
          <img src={officeImage3} alt="office3" className="office-image" />
          {/* <img src={officeImage4} alt="office4" className="office-image" /> */}
          <img src={officeImage5} alt="office5" className="office-image" />
          <img src={officeImage6} alt="office6" className="office-image" />
        </Slider>
      </div>

    </div>
  );
};

export default OurTeam;
